// View More Link
.view-more {
  display: block;
  padding: 1.1rem 0;
  text-align: center;
  margin-top: 0.5rem;
  font-size: 0.9rem;
  font-weight: $font-weight-bold;
  @include transition(color 300ms);

  &:not(.view-more--light) {
    color: $text-muted;

    &:hover {
      color: darken($text-muted, 10%);
    }
  }
}

.view-more--light {
  color: $white;

  &:hover {
    color: rgba($white, 0.8);
  }
}

.load-more {
  text-align: center;
  margin-top: 2rem;

  & > a {
    display: inline-block;
    padding: 0.5rem 1rem;
    border: 2px solid rgba($black, 0.065);
    border-radius: $border-radius;
    color: $body-color;
    @include transition(border-color 300ms);

    & > i {
      font-size: 1.2rem;
      vertical-align: middle;
      margin: 0 0.3rem 0 -0.1rem;
      @include transition(transform 300ms);
    }

    &:hover {
      border-color: rgba($black, 0.12);

      & > i {
        transform: rotate(-360deg);
      }
    }
  }
}

.card-block {
  .view-more {
    padding: 1rem 0 0;
  }
}

// Action Icons
.actions {
  [dropdown] {
    display: inline-block;
  }

  &:not(.actions--inverse) {
    .actions__item {
      color: lighten($body-color, 30%);

      &:hover {
        color: $body-color;
      }
      
      &.actions__item--active {
        color: darken($body-color, 10%);
      }
    }
  }
}

.actions__item {
  display: inline-block;
  vertical-align: top;
  line-height: 30px;
  text-align: center;
  font-size: 1.55rem;
  cursor: pointer;
  @include transition(color 300ms);
  @include size(30px, 30px);

  & > i {
    display: inline-block;
    width: 100%;
  }
}

.actions--inverse {
  .actions__item {
    color: rgba($white, 0.7);
    
    &:hover {
      color: $white;
    }
  }

  .actions__item--active {
    color: $white;
  }
}


// Icon Lists
.icon-list {
  list-style: none;
  padding: 0;
  margin: 0;

  & > li {
    position: relative;
    padding: 0.3rem 0;

    address {
      display: inline-block;
      vertical-align: top;
    }

    & > i {
      width: 2.5rem;
      text-align: center;
      font-size: 1.25rem;
      top: 0.12rem;
      position: relative;
      margin-left: -0.5rem;
    }
  }
}


// Avatars
.avatar-img,
.avatar-char {
  border-radius: 50%;
  @include size(3rem, 3rem);
  margin-right: 1.5rem;
}

.avatar-char {
  line-height: 3rem;
  text-transform: uppercase;
  font-size: 1.2rem;
  text-align: center;
  color: $white;
  font-style: normal;

  & > .zmdi {
    line-height: 3.1rem;
  }
}


// Backdrops
.ma-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: $sidebar-z-index - 1;
}


// Toolbar
.toolbar {
  display: flex;
  flex-direction: row;
  height: 4.5rem;
  align-items: center;
  padding: 0.05rem $card-spacer-x 0;
  position: relative;
  margin-bottom: $grid-gutter-width-base;

  &:not(.toolbar--inner) {
    @include card();
  }

  .actions {
    margin: 0.05rem -0.8rem 0 auto;
  }
}

.toolbar--inner {
  border-bottom: 1px solid #f6f6f6;
}

.toolbar__nav {
  white-space: nowrap;
  overflow-x: auto;
  margin-right: 1rem;

  & > a {
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    color: lighten($body-color, 25%);
    display: inline-block;
    @include transition(color 300ms);

    & + a {
      padding-left: 1rem;
    }

    &.active,
    &:hover {
      color: $body-color;
    }
  }
}

.toolbar__search {
  position: absolute;
  left: 0;
  top: 0;
  @include size(100%, 100%);
  background-color: $white;
  border-radius: $border-radius;
  padding-left: 3rem;
  display: none;

  input[type=text] {
    @include size(100%, 100%);
    border: 0;
    padding: 0 1.6rem;
    font-size: 1.2rem;
  }
}

.toolbar__search__close {
  @include transition(color 300ms);
  cursor: pointer;
  position: absolute;
  top: 1.5rem;
  left: 1.8rem;
  font-size: 1.5rem;
  color: lighten($body-color, 15%);

  &:hover {
    color: $body-color;
  }
}

.toolbar__label {
  margin: 0;
  font-size: 1.1rem;
}