.carousel-item {
  img {
    width: 100%;
    border-radius: $border-radius;
  }
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  position: relative;

  &:before,
  &:after {
    content: '';
    font-family: $font-family-icon;
    font-size: 2rem;
    color: $white;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-shadow: 0 0 5px rgba($black, 0.6);
    @include transition(opacity 250ms linear, transform 250ms linear);
  }

  &:after {
    transform: scale(5);
    opacity: 0;
  }
}

[class*="carousel-control-"] {
  &:hover {
    .carousel-control-prev-icon,
    .carousel-control-next-icon {

      &:after {
        transform: scale(1);
        opacity: 1;
      }

      &:before {
        transform: scale(0);
        opacity: 0;
      }
    }
  }
}

.carousel-control-prev-icon {
  &:before,
  &:after {
    content: '\f2ff';
  }
}

.carousel-control-next-icon {
  &:before,
  &:after {
    content: '\f301';
  }
}

.carousel-caption {
  background-color: rgba($black, 0.5);
  border-radius: $border-radius $border-radius 0 0;
  bottom: 0;
  font-weight: 500;
  padding-bottom: 35px;

  h3 {
    color: $white;
    font-size: 1.5rem;
  }
}