.widget-pictures__body {
  margin: 0;
  padding: 2px;
  text-align: center;
  @include clearfix();

  & > a {
    padding: 2px;
    display: block;

    img {
      width: 100%;
      border-radius: $border-radius;
    }

    &:hover {
      opacity: 0.9;
    }
  }
}