.modal-dialog {
  width: 100%;
}

.modal-content {
  text-align: left;
}

.modal-title {
  font-weight: normal;
  font-size: 1.1rem;
}

.modal-footer {
  padding-top: 10px;

  & > .btn-link {
    color: darken($body-color, 10%);
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
    border: 0;

    &:hover,
    &:focus {
      background-color: $gray-lighter;
      box-shadow: none;
    }
  }
}

@include media-breakpoint-up(sm) {
  .modal {
    text-align: center;

    &:before {
      content: '';
      height: 100%;
      width: 1px;
    }

    &:before,
    .modal-dialog {
      display: inline-block;
      vertical-align: middle;
    }
  }
}