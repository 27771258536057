/*
* @Author: Sem van Gelooven
* @Date:   2017-09-22 14:33:51
* @Last Modified by:   Sem van Gelooven
* @Last Modified time: 2017-10-04 09:26:56
* Use this file to customize and override the default admin theme
*/


.actions {
    text-align: right;
}

.help-block {
    display: inline-block;
}

table {
    th.action_wrapper {
        width: 120px;
    }
}

.mjs-nestedSortable-error {
    background: #fbe3e4;
    border-color: transparent;
}

#tree {
    width: 550px;
    margin: 0;
}

ol {
    max-width: 450px;
    padding-left: 25px;
}

ol.sortable,ol.sortable ol {
    list-style-type: none;
}

.sortable li div {
    border: 1px solid #ddd;
    position: relative;
    padding: 10px 15px;
    height: auto;
    min-height: 20px;
    line-height: 30px;
    overflow: hidden;
    word-wrap: break-word;
}

li.mjs-nestedSortable-collapsed.mjs-nestedSortable-hovering div {
    border-color: #999;
}

.disclose, .expandEditor {
    cursor: pointer;
    width: 20px;
    display: none;
}

.sortable li.mjs-nestedSortable-collapsed > ol {
    display: none;
}

.placeholder {
    outline: 1px dashed #ccc;
    margin-bottom: 5px;
}

.sortable li.mjs-nestedSortable-branch > div > .disclose {
    display: inline-block;
}

.sortable span.ui-icon {
    display: inline-block;
    margin: 0;
    padding: 0;
}

.menuDiv {
    background: #EBEBEB;
}

.menuEdit {
    background: #FFF;
}

.itemTitle {
    vertical-align: middle;
    cursor: pointer;
}

.deleteMenu {
    float: right;
    cursor: pointer;
}

.card-menu-node {

    i {
        font-size: 16px;

        &.zmdi-link {
            font-size: 20px;
            vertical-align: middle;
        }
    }

    .delete-from-menu {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);

        i {
            cursor: pointer;
        }
    }
}

.card-block {
    table {
        td {
            &.item-inactive {
                font-style:italic;
                font-weight:lighter;
            }
        }
    }
}

.table-sortable {
	tr {
		cursor: move;
	}
}

/* Froala */
.fr-inline {
	border-bottom: 1px solid #f1f1f1;
}

/* bootstrap 4 material admin theme */
.form-group__bar {
	&.active {
		&:before,
		&:after {
		    width: 50%;
		}
	}
}

.page-loader {
	&.page-loader__transparent {
		background: $body-bg;
		background-color: rgba($body-bg, 0.8);
	}
}

/* Overrides */
$fa-font-path:        "../../fonts" !default;

$flag-icon-css-path: '../img/flags' !default;

.text-lightred {
    color: #ff5652;
}