.widget-profile {
  background-color: $light-blue;

  .card-header {
    background-color: rgba($white, 0.1);
    text-align: center;

    .btn--float {
      color: $light-blue;
    }
  }

  .avatar-char {
    background-color: rgba($white, 0.95);
    color: $light-blue;
    margin-right: 1.2rem;
  }
}

.widget-profile__img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 1.2rem;
  border: 5px solid rgba($white, 0.1);
}

.widget-profile__list {
  color: $white;

  .media {
    padding: 1rem 2rem;

    &:nth-child(even) {
      background-color: rgba($white, 0.1);
    }
  }

  .media-body {
    strong{
      display: block;
      font-weight: 500;
    }

    small {
      color: rgba($white, 0.8);
      font-size: 0.92rem;
    }
  }
}