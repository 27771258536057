.btn {
  cursor: pointer;

  &:not(.btn-secondary):not([class*="btn-outline-"]):not(.btn-link) {
    box-shadow: $btn-box-shadow;
    border: 0;
  }
}

.btn-secondary {
  &,
  &:focus {
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);
  }
}

.btn--white {
  &,
  &:hover,
  &:focus {
    background-color: $white;
    color: $body-color;
  }
}

.btn--icon {
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  line-height: 2.9rem;
  padding: 0;
  font-size: 1.2rem;
  text-align: center;
}

.btn--icon-text {
  & > .zmdi {
    display: inline-block;
    vertical-align: top;
    margin: 1px 5px 0 0;
    line-height: 100%;
  }
}

.btn--action {
  z-index: 2;
  height: 50px;
  width: 50px;
  padding: 0;
  line-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 20px;
  cursor: pointer;
  box-shadow: 0 3px 7px rgba($black, 0.15);

  &,
  &:hover,
  &:focus {
    color: $white;

  }
}

.btn--fixed {
  position: fixed !important;
  bottom: 30px;
  right: 30px;
}

.btn-group-justified {
  display: flex;
  width: 100%;
  .btn,
  .btn-group {
    flex: 1;
    .btn {
      width: 100%;
    }
  }
}

[data-toggle="buttons"]:not(.btn-group--colors) {
  & > .btn {
    background-color: #ebebeb;
    color: $body-color;
    cursor: pointer;
    box-shadow: none !important;
    border: 0 !important;
    margin: 0;

    &.active {
      background-color: $light-blue;
      color: $white;
    }
  }
}

.btn-group--colors {
  & > .btn {
    box-shadow: none !important;
    border-radius: 50% !important;
    width: 30px;
    height: 30px;
    margin-right: 3px;
    cursor: pointer;
    position: relative;

    &:before {
      @include font-icon('\f26b', 16px);
      @include transition(transform 200ms, opacity 200ms);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      line-height: 28px;
      padding-right: 3px;
      color: $white;
      font-style: italic;
      transform: scale(0);
      opacity: 0;
    }

    &.btn:not([class*="bg-"]) {
      border: 1px solid darken($input-border-color, 5%);
      
      &:before {
        color: $body-color;
      }
    }

    &.active {
      &:before {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
}